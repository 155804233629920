<template>
  <div id="search" class="searchable-area-content tw-relative">
    <a-auto-complete
      v-model:value="searchQuery"
      :filter-option="false"
      :options="searchResults ? searchResults : false"
      class="search-box"
      size="large"
      :dropdown-match-select-width="false"
      :dropdown-menu-style="{
        width: '500px !important',
      }"
      @search="fetchResults"
    >
      <template #option="result">
        <div class="tw-w-full result-wrapper" @click="goToResult(result)">
          <div class="tw-w-full tw-flex tw-jutify-between result-result">
            <div class="tw-flex tw-w-2/3 tw-items-center tw-grow-1">
              <p
                class="tw-whitespace-pre-line tw-mb-0 tw-mt-0 tw-flex tw-tw-content-center"
                v-html="result.name || result.resource_id.toString()"
              ></p>
              <span
                v-if="false"
                class="fe-icon tw-bg-gray-4 tw-ml-2 tw-h-6 tw-w-6 tw-rounded-md tw-flex tw-items-center tw-justify-center"
                ><EnvironmentFilled :style="{ color: '#1890FF' }"
              /></span>
            </div>
            <span
              class="tw-w-1/3 tw-text-xs tw-font-medium tw-uppercase tw-text-gray-300 tw-text-right tw-mb-0 tw-justify-self-end tw-block"
              >{{ result.resource_type }}</span
            >
          </div>
          <p
            v-if="result.resource_type === 'user' && result.description"
            class="tw-w-3/4 tw-whitespace-pre-line tw-mb-0 tw-mt-0 tw-flex tw-tw-content-center"
            v-html="result.description"
          ></p>
        </div>
      </template>
      <a-input-search size="large" placeholder="Search"
        ><SearchOutlined slot="prefix" class="certain-category-icon"
      /></a-input-search>
    </a-auto-complete>
    <a-input-group size="large" class="search-category-select">
      <a-select
        v-model:value="activeCategory"
        class="tw-uppercase"
        size="large"
        :options="categories"
      >
      </a-select>
    </a-input-group>
  </div>
</template>

<script lang="ts" setup>
import { SearchOutlined, EnvironmentFilled } from '@ant-design/icons-vue'
import { SearchResult } from '~/interfaces/api/Search.interface'
import { debounce } from '~/utils'

const router = useRouter()
const route = useRoute()

const categories = ref([])
const activeCategory = ref('all')

if (useHasPermission('superuser'))
  categories.value.push(
    { value: 'all', label: 'ALL' },
    { value: 'user', label: 'USERS' },
    { value: 'product', label: 'PRODUCT' },
    { value: 'company', label: 'COMPANY' }
  )
else {
  if (useHasPermission('support')) {
    categories.value.push({ value: 'user', label: 'USERS' })
    categories.value.push({ value: 'company', label: 'COMPANY' })
  }
  if (useHasPermission('form_editor'))
    categories.value.push({ value: 'product', label: 'PRODUCT' })
}

const searchQuery = ref('')
const isSearching = ref(false)
const showAll = ref(false)
const searchResults = ref([] as SearchResult[])
const options = ref([] as SearchResult[])

const fetchResults = async () => {
  isSearching.value = true
  let results: any
  if (searchQuery.value)
    results = await useFetchAuth('search', {
      params: {
        query: searchQuery.value,
        type: activeCategory.value,
      },
    })
  if (results) {
    if (results.meta.total > 10) {
      showAll.value = true
    }
    searchResults.value = results.data
    isSearching.value = false
  }
}

const getResultSlug = (string: string) => {
  const splitString = string.split('/')
  return splitString[splitString.length - 1]
}
const debounceInput = debounce(() => {
  if (searchQuery.value) {
    isSearching.value = true
    fetchResults()
  }
}, 300)

const change = () => {
  options.value = searchResults.value
}

const goToResult = (value) => {
  searchQuery.value = ''
  if (value.resource_type === 'user')
    navigateTo({
      path: `/users/${value.resource_id.toString()}`,
    })
  else if (value.resource_type === 'product')
    navigateTo({
      path: `/products/${value.resource_id.toString()}`,
    })
  else if (value.resource_type === 'company')
    navigateTo({
      path: `/companies/${value.resource_id.toString()}`,
    })
}
</script>

<style lang="scss">
.result-wrapper {
  min-width: 400px;

  .result-result {
    min-width: 400px;
    width: 100% !important;
  }
}
.searchable-area-content {
  width: 400px;
  display: flex;
  height: 40px;

  .ant-input-group {
    display: flex;
  }

  .search-box {
    width: 400px !important;
    position: relative;
    z-index: 2;
    max-width: 400px;

    .ant-input-group-addon {
      position: absolute;
      z-index: 1;
      left: 10px !important;
      background-color: transparent;

      svg {
        color: #95a0b7;
      }

      .ant-btn {
        border: 0;
        width: 30px;
        background-color: transparent;
      }
    }
    .ant-input-lg {
      height: 40px;
      background-color: #464e61;
      transition: 0.2s all ease-in;
    }

    .ant-select-dropdown {
      width: 400px !important;
    }

    .ant-select-search__field__wrap {
      display: flex;
      align-items: center;
    }
    .ant-select-selection {
      background-color: #464e61;
      border-radius: 2px 0 0 2px;

      i {
        color: #95a0b7;
      }
    }
    .ant-input-group.ant-input-group-compact {
      display: flex;
      justify-content: space-between;
      .ant-select {
        border: 0;
        width: 115px;
      }
    }
    input {
      border: 0;
      color: #95a0b7;
      font-size: 14px;
      padding-left: 34px;
    }
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #95a0b7;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #95a0b7;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #95a0b7;
    }
  }

  .search-box.ant-select-focused {
    .ant-input-lg {
      background-color: white;
      i {
        color: black;
      }
    }
    input {
      color: black;
    }
  }
  .search-category-select {
    width: 100px;
    .ant-select-selector {
      background-color: #464e61;
      font-size: 12px;
      border: 0;
      border-left: 1px solid #505769;
      color: #95a0b7;
      border-radius: 0 2px 2px 0;
      min-width: 110px;
    }
    .ant-select-arrow {
      color: #95a0b7;
      margin-left: 5px;

      i {
        font-size: 8px;
      }
    }
  }
}

.content-cover {
  position: fixed;
  z-index: 20;
  background: rgba(0, 0, 0, 0.13);
  bottom: 0;
  left: 0;
  right: 0;
  height: calc(100% - 4rem);
}
</style>
